const palette = {
    inkySepBlue: '#008adb',
    inkySepSky: '#33a9d8',
    inkySepOcean: '#9bc3c2',
    inkySepPink: '#e8008c',
    inkmannRed: '#cc0033',
    inkmannRedShade: '#a50029',
    inkySkyBlue: '#b7e3fb',
    inkySkyBlueShade: '#9ed6f1',
    inkmannGreen: '#09a805',
    inkmannGreenShade: '#0c9309',
    orange: '#e93e30',
    orangeHighlight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    colorGrey: '#f7f7f7',
    inkmannBlack: '#333333',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'inkmann',
    palette,
    radius: '3px',
    col: {
        primary: {
            alt: 'white',
            fg: palette.inkmannGreen,
            peer: palette.inkmannGreenShade,
        },
        secondary: {
            alt: 'white',
            fg: palette.inkmannRed,
            peer: palette.inkmannRedShade,
        },
        tertiary: {
            alt: 'white',
            fg: palette.inkmannGreen,
            peer: palette.inkmannGreenShade,
        },
        panel: {
            alt: '#343434',
            fg: palette.grayLight,
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#343434',
            fg: 'white',
            peer: palette.blackGradientb,
        },
        vars: {
            priceDiscountActive: '#007daf',
        },
    },
    skipColorClasses: true,
    font: {
        family: 'Arial, Helvetica, sans-serif',
        header: {
            family: 'Arial, ​Helvetica, ​sans-serif',
        },
        size: {
            // /** @option {string} font.size.x3 */
            // x3: '1.5rem',
            // /** @option {string} font.size.x2 */
            // x2: '1.8em',
            // /** @option {string} font.size.x1 */
            // x1: '18px',
            // /** This is the default font size
            //  * @option {string} font.size.m */
            // m: '1.15rem',
            // /** @option {string} font.size.s0 */
            // s0: '1.0em',
            // /** @option {string} font.size.s1 */
            // s1: '0.85em',
            // /** @option {string} font.size.s2 */
            // s2: '0.55em',
            /** @option {string} font.size.x4 */
            x4: '32px',
            /** @option {string} font.size.x3 */
            x3: '24px',
            /** @option {string} font.size.x2 */
            x2: '20px',
            /** @option {string} font.size.x1 */
            x1: '18px',
            /** This is the default font size
             * @option {string} font.size.m */
            m: '15.6px',
            /** @option {string} font.size.s0 */
            s1: '14px',
            /** @option {string} font.size.s1 */
            s2: '12px',
            /** @option {string} font.size.s2 */
            s3: '10px',
        },
    },
    conf: {
        showPDPLink: true,
        showBreadCrumbs: true,
    },
};
export default theme;
